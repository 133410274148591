export const CONTENT = {
  email: "info@auspisprime.com",
  phone1: "+234 913 967 0041",
  phoneWhatsApp: "2347059696762",

  address_1:
    "No 18 Say Plaza, 89 A.E. Ekukinam Street Opposite Utako market, Utako, Abuja",

  what_we_do: {
    wwd_1: "Paint Manufacturing",
    wwd_2: "Real Estate Finishing & Facelifting",
    wwd_3: "Professionals you can trust.",
  },
  social_media: {
    INSTAGRAM: {
      title: "Instagram",
      url: "https://www.instagram.com/auspisprime/",
    },
    Twitter: {
      title: "Twitter",
      url: "https://twitter.com/AuspisPrime",
    },
    Facebook: {
      title: "Facebook",
      url: "https://web.facebook.com/profile.php?id=61557896301439",
    },

    LINKEDIN: {
      title: "Linkedin",
      url: "https://linkedin.com/company/auspisprime",
    },
  },
};
