import { CONTENT } from "../content";

export const HandleSubmitContactForm = (contactObject) => {
  const { name, email, message } = contactObject;
  const msgToSend =
    "My name is " + name + " - " + email + ` am inquiring on \n ${message}`;
  const finalMsg = encodeURIComponent(msgToSend);
  if (name?.length > 2 && email?.length > 5 && message?.length > 5) {
    window.open(
      `https://wa.me/${CONTENT.phoneWhatsApp}?text=${finalMsg}`,
      "_blank",
      "noreferrer"
    );
  } else {
    alert("Please fill all form field");
  }
};
