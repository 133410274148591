import React from "react";
import { NavLink } from "react-router-dom";
import Header4 from "../Common/Header4";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";

const filters = [
  { label: "Furniture", filter: ".cat-3" },
  { label: "Interiors", filter: ".cat-2" },
  { label: "Exterior", filter: ".cat-1" },
];

const projects = [
  {
    image: require("./../../images/projects/Exterior/pic1.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic2.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic3.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic4.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic5.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic6.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic7.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic8.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic9.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic10.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic11.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic12.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic13.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic14.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic15.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic16.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic17.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic18.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/Exterior/pic19.jpg"),
    title: "",
    address: "",
    filter: "cat-1",
  },

  {
    image: require("./../../images/projects/Interiors/pic1.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic2.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic3.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic4.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic5.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic6.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic7.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic8.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic9.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic10.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic11.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic12.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic13.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic14.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic15.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic16.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic17.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic18.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic19.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic20.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic21.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic22.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic23.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic24.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic25.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic26.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic27.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic28.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic29.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic30.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic31.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic32.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic33.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/Interiors/pic34.jpg"),
    title: "",
    address: "",
    filter: "cat-2",
  },

  {
    image: require("./../../images/projects/Furniture/pic1.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic2.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic3.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic4.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic5.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic6.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },

  {
    image: require("./../../images/projects/Furniture/pic8.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic9.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic10.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic11.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic12.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic13.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic14.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic15.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic16.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic17.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic19.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic20.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic21.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic22.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },

  {
    image: require("./../../images/projects/Furniture/pic24.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic25.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic26.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic27.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic28.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic29.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic30.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic31.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic32.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic33.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic34.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic35.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic36.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic37.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic38.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic39.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic40.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic41.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic42.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic43.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic44.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic45.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic46.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic47.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic48.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic49.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic50.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic51.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic52.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic53.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic54.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic55.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic56.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic57.jpg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic58.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic59.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/Furniture/pic60.jpeg"),
    title: "",
    address: "",
    filter: "cat-3",
  },
];

var bnrimg = require("./../../images/background/bg-1.jpg");
var bgimg1 = require("./../../images/background/cross-line.png");

class ProjectGridNoGap4 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }

  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner
            title="Our Projects"
            pagename="Our Projects"
            description="Projects that we're proud of"
            bgimage={bnrimg}
          />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 column-grid-4 inner-page-padding">
            <div className="container">
              {/* Filter Nav START */}
              <div className="filter-wrap p-b30 text-center">
                <ul className="filter-navigation masonry-filter clearfix">
                  <li className="active">
                    <NavLink
                      to={"#"}
                      className="btn from-top"
                      data-filter="*"
                      data-hover="All"
                    >
                      All
                    </NavLink>
                  </li>
                  {filters.map((item, index) => (
                    <li key={index}>
                      <NavLink
                        to={"#"}
                        className="btn from-top"
                        data-filter={item.filter}
                      >
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Filter Nav END */}
              {/* GALLERY CONTENT START */}
              <ul className="masonry-outer mfp-gallery work-grid clearfix list-unstyled no-col-gap m-b60">
                {projects.map((item, index) => (
                  <div
                    key={index}
                    className={`${item.filter} masonry-item col-xl-3  col-lg-4 col-md-6 col-sm-12`}
                  >
                    <div className="sx-box image-hover-block">
                      <div className="sx-thum-bx">
                        <img
                          src={item.image}
                          alt="AUSPISPRIME FURNITURE INTERIOR AND EXTERIOR WORKS"
                        />
                      </div>
                      <div className="sx-info  p-t20 text-white">
                        <h4 className="sx-tilte">
                          <NavLink to={"/project-detail1"}>
                            {item.title}
                          </NavLink>
                        </h4>
                        <p className="m-b0">{item.address}</p>
                      </div>
                      <a className="mfp-link" href={item.image}>
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                ))}
              </ul>
              {/* GALLERY CONTENT END */}
              <div
                className="text-center load-more-btn-outer"
                style={{ backgroundImage: "url(" + bgimg1 + ")" }}
              >
                {/* <button className="site-button-secondry btn-half">
                  <span>Load More</span>
                </button> */}
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectGridNoGap4;
