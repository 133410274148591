import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/pic7.jpg");
var video = require("../../images/projects/mp4/pic1.mp4");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">Auspis-Prime</h3>
                    <p>
                      {" "}
                      Auspis-prime Nig Ltd is a company registered with the
                      Corporate Affairs Commission specializing in Real Estate,
                      Paint manufacturing and Interiors. Our expertise
                      encompasses a wide array of services, including
                      construction, painting, plastering, tiling, POP and
                      furniture production. With a commitment to quality and
                      innovation, Auspis-prime stands as a reliable partner for
                      clients seeking comprehensive solutions to enhance their
                      spaces.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <NavLink to="/services-detail">
                          We produce painting for interior and exterior design.
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services-detail">
                          We produce furnitures for interior designs.
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services-detail">
                          We provide high-quality real estate services for
                          clients.
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services-detail">
                          Our specialists are ready to consult you on any topic.
                        </NavLink>
                      </li>
                    </ul>
                    <div className="text-left">
                      <NavLink
                        to="/services-detail"
                        className="site-button btn-half"
                      >
                        <span>Read More</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content">
                          <NavLink
                            to={"#"}
                            className="play-now"
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            <i className="icon fa fa-play" />
                            <span className="ripple" />
                          </NavLink>

                          <div className="video-section-bottom">
                            <h3 className="sx-title text-white">
                              5 Years
                              <br />
                              Experience
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactPlayer url={video} controls loop />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
