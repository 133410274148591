import React from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

var bnrimg = require("./../../images/pic4.jpg");

class Faq extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner
            title="Frequently Asked Questions"
            pagename="Faq"
            description="Here are top answers to frequently asked question"
            bgimage={bnrimg}
          />
          {/* SECTION CONTENTG START */}
          <div className="section-full p-t80 p-b80 inner-page-padding">
            <div className="container">
              <div className="faq-1">
                {/* TITLE START */}
                <div className="section-head">
                  <div className="sx-separator-outer separator-left">
                    <div
                      className="sx-separator bg-white bg-moving bg-repeat-x"
                      style={{
                        backgroundImage:
                          "url(images/background/cross-line2.png)",
                      }}
                    >
                      <h3 className="sep-line-one">ASK AUSPIS-PRIME</h3>
                    </div>
                  </div>
                </div>
                {/* TITLE END */}
                <div className="row">
                  <div className="col-md-6">
                    {/* Accordian */}
                    <div className="sx-accordion acc-bg-gray" id="accordion5">
                      <div className="panel sx-panel">
                        <div className="acod-head acc-actives">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne5"
                              data-parent="#accordion5"
                            >
                              What services does Auspis-prime offer ?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseOne5"
                          className="acod-body collapse show"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              We specialize in real estate, paint manufacturing,
                              and interior services. We provide comprehensive
                              solutions including construction, painting,
                              plastering, tiling, POP (Plaster of Paris), and
                              furniture production.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How are you different from other companies in the
                              market
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              We set ourself apart through our commitment to
                              quality and innovation. We prioritize client
                              satisfaction by delivering top-notch services and
                              employing cutting-edge techniques in all our
                              projects.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Can you handle large-scale construction projects ?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Absolutely. We have the expertise and resources to
                              undertake projects of varying scales. Whether it's
                              residential, commercial, or industrial
                              construction, we have the capabilities to deliver
                              exceptional results.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFour5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              What is our approach to paint manufacturing ?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              At Auspis-prime, we take pride in our paint
                              manufacturing process, which emphasizes quality,
                              durability, and environmental sustainability. Our
                              paints are formulated to withstand diverse
                              conditions while adhering to the highest industry
                              standards.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Do you offer customized interior solutions?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Yes, we offer tailor-made interior solutions to
                              meet the unique requirements of our clients. From
                              concept development to execution, we work closely
                              with our clients to bring their vision to life.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseSix6"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How do you ensure customer satisfaction?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSix6" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Customer satisfaction is at the core of
                              Auspis-prime's ethos. We prioritize open
                              communication, attention to detail, and adherence
                              to timeliness to ensure that our clients are
                              delighted with the end results of their projects.
                              We have a feedback system in place to listen to
                              our customer concern and address it adequately.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseSeven7"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Is Auspis-prime committed to sustainability
                              practices ?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSeven7" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Absolutely. Auspis-prime is dedicated to
                              sustainable business practices across all facets
                              of our operations. From eco-friendly paint
                              formulations to responsible construction methods,
                              we strive to minimize our environmental footprint.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseEight8"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Can you provide references or examples of past
                              projects ?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseEight8" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Safety is a top priority at Auspis-prime. We
                              strictly adhere to industry safety standards and
                              protocols, conduct regular safety inspections, and
                              provide comprehensive training to our staff to
                              ensure a secure working environment on all our
                              construction sites.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseNine9"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How do you ensure safety on construction sites?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseNine9" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Safety is a top priority at Auspis-prime. We
                              strictly adhere to industry safety standards and
                              protocols, conduct regular safety inspections, and
                              provide comprehensive training to our staff to
                              ensure a secure working environment on all our
                              construction sites.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTen10"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              What regions does Auspis-prime serve ?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTen10" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Auspis-prime operates across Nigeria, serving
                              clients in various regions with our range of real
                              estate, paint manufacturing, and interior
                              services. We are committed to extending our reach
                              and delivering excellence wherever our clients
                              need us.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 faq-list">
                    {/* Accordian */}
                    <div>
                      <h4>
                        For other Enquiries that have not been captured in the
                        FAQs section:
                      </h4>
                      <p>
                        Do you have questions, or need assistance with
                        regards to our product/service? Please contact us by
                        sending a mail to info@auspisprime.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default Faq;
