import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: "01",
    title: "Plans and Projects",
    flaticon: "flaticon-sketch",
    description:
      "We organize and plan out each project in a well detailed manner to give client comprehensive understanding of the project.",
  },
  {
    count: "02",
    title: "Interior",
    flaticon: "flaticon-stairs",
    description:
      "Analysis and planning services that help both the client and architects to work out the forthcoming projects with ease.",
  },
  {
    count: "03",
    title: "Exterior",
    flaticon: "flaticon-window",
    description:
      "We offer comprehensive Architectural Engineering Services including Exterior design, Master planning, 3D modeling among others",
  },
  {
    count: "04",
    title: "Real Estate",
    flaticon: "flaticon-skyline",
    description:
      "Real Estate is defined as the land and any permanent structures. We develop and provide guideline to real estate development",
  },
  {
    count: "05",
    title: "Furniture",
    flaticon: "flaticon-bed",
    description:
      "Our team also provides consultations on all Furniture issues, even if you need specific information about furniture works.",
  },
  {
    count: "06",
    title: "Paint Manufacturing",
    flaticon: "flaticon-door",
    description:
      "We combine Interior and Exterior Design services and often provide them as a single solution to our client.",
  },
];

var bgimg1 = require("./../../images/background/cross-line2.png");

class Services1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80  p-b50 bg-gray">
          <div className="section-content">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head">
                <div className="sx-separator-outer separator-left">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                  >
                    <h3 className="sep-line-one ">All Services</h3>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div className="row">
                {services.map((item, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 m-b30"
                    key={index}
                  >
                    <div className="sx-icon-box-wraper  icon-count-2-outer">
                      <div className="icon-count-2 bg-white">
                        <span className="icon-count-number">{item.count}</span>
                        <div className="icon-xl inline-icon m-b5 scale-in-center">
                          <span className="icon-cell">
                            <i className={item.flaticon} />
                          </span>
                        </div>
                        <div className="icon-content">
                          <h4 className="sx-tilte">{item.title}</h4>
                          <p>{item.description}</p>
                          <div className="text-left">
                            <NavLink
                              to={"/services-detail"}
                              className="site-button-link"
                            >
                              Learn More
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Services1;
