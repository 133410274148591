import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const images = [
  require("../../images/projects/portrait/pic20.jpg"),
  require("../../images/projects/portrait/pic28.jpg"),
  require("../../images/projects/portrait/pic27.jpg"),
  require("../../images/projects/portrait/pic29.jpg"),
  require("../../images/projects/portrait/pic10.jpg"),
];

var bgimg1 = require("./../../images/background/bg-4.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class About1 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      margin: 30,
      nav: true,
      dots: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        991: {
          items: 1,
        },
      },
    };
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b80 bg-white bg-no-repeat bg-bottom-left"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">About us</h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="about-home-left">
                    <h3 className="m-t0 sx-tilte">
                      We are Auspis-prime Nig Ltd{" "}
                    </h3>
                    <p style={{textAlign: "justify"}}>
                      Auspis-Prime Nig Ltd is a company registered with the
                      Corporate Affairs Commission specializing in Real Estate,
                      Paint manufacturing and Interiors. Our expertise
                      encompasses a wide array of services, including
                      construction, painting, plastering, tiling, POP and
                      furniture production. With a commitment to quality and
                      innovation, Auspis-prime stands as a reliable partner for
                      clients seeking comprehensive solutions to enhance their
                      spaces.
                    </p>
                    <div className="text-left">
                      <NavLink
                        to="/about"
                        className="site-button-secondry btn-half"
                      >
                        <span>Read More</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="about-home-right">
                    <OwlCarousel
                      className="owl-carousel about-home number-slider owl-btn-vertical-center"
                      {...options}
                    >
                      {images.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="sx-img-effect zoom-slow">
                            <NavLink
                              to={"/about"}
                              style={{
                                height: "500px",
                                minHeight: "500px",
                                display: "block",
                              }}
                            >
                              <img
                                src={item}
                                alt=""
                                style={{ objectFit: "cover", height: "100%" }}
                              />
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                    <div
                      className="about-home-before"
                      style={{ height: "460px" }}
                    >
                      <img
                        src={require("../../images/projects/portrait/pic12.jpg")}
                        alt="Auspin prime"
                        style={{ objectFit: "cover", height: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About1;
