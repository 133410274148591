import React from "react";
import { NavLink } from "react-router-dom";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import ReactPlayer from "react-player";

var bnrimg = require("./../../images/pic4.jpg");

class ServiceDetail extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner
            title="Service Details"
            pagename="Service Detail"
            description="Auspis-Prime Services"
            bgimage={bnrimg}
          />

          <div className="section-full p-t80 p-b50 mobile-page-padding">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-12 sticky_column">
                  <div className="about4-section m-b20 bg-gray p-a20">
                    <div className="widget widget_services">
                      <h4 className="widget-title"> How we work </h4>
                      <ul className="bg-white">
                        <li>
                          <NavLink to={"#"}> Briefing </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>Design Phase</NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>Agreement</NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>Production</NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}> Installation </NavLink>
                        </li>
                        {/* <li>
                          <NavLink to={"#"}>Exterior</NavLink>
                        </li> */}
                      </ul>
                    </div>
                    <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                      <div className="btn-block">
                        <span className="text-black m-r10">
                          <i className="fa fa-phone" />
                        </span>
                        <strong className="text-uppercase text-black">
                          CALL: +2347059696762
                        </strong>
                      </div>
                    </div>
                    <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                      <a
                        href="https://wa.me/2347059696762"
                        target="_blank"
                        rel="noreferrer"
                        className="btn-block"
                      >
                        <span className="text-black m-r10">
                          <i className="fa fa-whatsapp" />
                        </span>
                        <strong className="text-uppercase text-black">
                          WHATSAPP
                        </strong>
                      </a>
                    </div>
                    <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                      <a
                        href="mailto:info@auspisprime.com"
                        rel="noreferrer"
                        className="btn-block"
                      >
                        <span className="text-black m-r10">
                          <i className="fa fa-envelope" />
                        </span>
                        <strong className="text-uppercase text-black">
                          EMAIL: info@auspisprime.com
                        </strong>
                      </a>
                    </div>
                    <div className="about4-content m-t30">
                      <h4>GET 10% OFF TODAY</h4>
                      <p>
                        Get in touch today and receive 10% off, on any of our
                        services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="section-content">
                    <div className="service-single-block m-b30">
                      <div className="sx-media" style={{ height: "300px" }}>
                        <img
                          src={require("./../../images/projects/portrait/pic30.jpg")}
                          alt=""
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <h5
                        className="m-t30 sx-tilte"
                        style={{ color: "#574e4e" }}
                      >
                        Paint Manufacturing
                      </h5>
                      <p style={{ textAlign: "justify" }}>
                        Auspis-prime takes pride in producing premium-quality
                        paints tailored to meet the diverse needs of our
                        clients. Our state-of-the-art manufacturing processes
                        ensure that our paints not only beautify but also
                        provide long-lasting protection for residential,
                        commercial, and industrial surfaces.
                      </p>
                      <h5
                        className="m-t30 sx-tilte"
                        style={{ color: "#574e4e" }}
                      >
                        Real Estate Finishing & Facelifting
                      </h5>
                      <p style={{ textAlign: "justify" }}>
                        From meticulous painting services to expert plastering,
                        precise tiling, and creative POP applications,
                        Auspis-prime offers a complete suite of solutions to
                        elevate the aesthetic appeal of real estate properties.
                        Our skilled team transform spaces, leaving a lasting
                        impression on every project.
                      </p>
                      <h5
                        className="m-t30 sx-tilte"
                        style={{ color: "#574e4e" }}
                      >
                        Furniture
                      </h5>
                      <p style={{ textAlign: "justify" }}>
                        To Auspis-prime, furniture is not merely an object; it
                        is a statement—an embodiment of refined taste and
                        refined living. Whether adorning the halls of a chic
                        urban apartment or gracing the serene spaces of a
                        luxurious estate, our custom furniture seamlessly
                        integrates into diverse environments, elevating the
                        ambiance with their presence of your hotel, residential
                        and office spaces.
                      </p>

                      <h4
                        className="m-t30 sx-tilte"
                        style={{ color: "#574e4e" }}
                      >
                        Professional Interior Design Service
                      </h4>
                      <div className="single-service-list">
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <ul className="list-angle-right anchor-line">
                              <li>We provide high quality design services</li>
                              <li>We are confident about our projects</li>
                              <li>A good Work Environment For work</li>
                              <li>Communication Skills to getting in touch</li>
                              <li> Renovations Benefit of Service</li>
                            </ul>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <ul className="list-angle-right anchor-line">
                              <li>Sustainability and modern tech</li>
                              <li>We provide the best and are trustworthy</li>
                              <li>
                                Scientific Skills For getting a better result
                              </li>
                              <li>
                                Historic Renovations and Restorations designs
                              </li>
                              <li>Project on time and Latest Design</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* <div className="sx-media sx-img-effect zoom-slow">
                        <div className="sx-thum-bx sx-img-overlay1 sx-img-effect yt-thum-box">
                          <img
                            src="https://img.youtube.com/vi/Oy2QIiSQT2U/0.jpg"
                            alt=""
                          />
                          <NavLink
                            to={"#"}
                            className="play-now"
                            data-toggle="modal"
                            data-target="#myModal6"
                          >
                            <i className="icon fa fa-play" />
                            <span className="ripple" />
                          </NavLink>
                        </div>
                      </div> */}
                    </div>
                    <div
                      className="sx-accordion acc-bg-gray m-b30"
                      id="accordion5"
                    >
                      <div className="panel sx-panel">
                        <div className="acod-head acc-actives">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne5"
                              data-parent="#accordion5"
                            >
                              Briefing
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapseOne5"
                          className="acod-body collapse show"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              We initiate the process with a personalized
                              discussion with the client, understanding their
                              specific requirements and preferences for their
                              real estate, interior, paint, or furniture
                              projects. Our team provides professional advice
                              tailored to the client's needs.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Design Phase
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseTwo5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Taking into account the client's brief and the
                              constraints of the space, we meticulously craft a
                              well-thought-out design proposal. This design is
                              tailored to meet the client's objectives while
                              maximizing the potential of the space.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Agreement
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseThree5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Once the proposed design has been thoroughly
                              reviewed and finalized in consultation with the
                              client, we proceed to formalize the agreement.
                              This stage marks the mutual understanding of the
                              project scope, budget, and timeline, paving the
                              way for production to commence.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFour5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Production
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseFour5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              With the agreement in place, our skilled team
                              begins the production process. Whether it's
                              crafting bespoke furniture pieces, manufacturing
                              high-quality paints, executing interior designs,
                              or developing real estate projects, we adhere to
                              the detailed scope or drawings to ensure precise
                              execution that best complements the space.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Installation
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseFive5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              The culmination of our work process is the
                              installation phase. After production is completed
                              to satisfaction, our experienced team undertakes
                              the installation on-site. This final step ensures
                              that the vision outlined in the design phase is
                              brought to life, leaving the client with a space
                              that exceeds their expectations in terms of
                              aesthetics and functionality.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 m-b30">
                        <NavLink to={"/projects"}>
                          <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                            <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                              <span className="icon-cell sx-text-primary">
                                <i className="flaticon-door" />
                              </span>
                            </div>
                            <div className="icon-content">
                              <h4 className="sx-tilte">View all Project</h4>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                      {/* <div className="col-md-6 m-b30">
                        <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                          <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                            <span className="icon-cell sx-text-primary">
                              <i className="flaticon-ruler-1" />
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="sx-tilte">Solution</h4>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and setting as Planning.
                            </p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="myModal6" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactPlayer url="https://www.youtube.com/watch?v=Oy2QIiSQT2U" />
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default ServiceDetail;
